import { ethers } from "ethers";

export class NoWalletError extends Error {
  constructor() {
    super('No wallet detected! Please install MetaMask.');
    this.name = 'NoWalletError';
  }
}

export class UserRejectedRequestError extends Error {
  constructor() {
    super('User rejected request.');
    this.name = 'UserRejectedRequestError';
  }
}

export class UnsupportedOperationError extends Error {
  constructor() {
    super('Unsupported operation.');
    this.name = 'UnsupportedOperationError';
  }
}

export class IncorrectChainError extends Error {
  constructor() {
    super('Please ensure your wallet is connected to Berachain');
    this.name = 'IncorrectChainError';
  }
}

export const getBlockHeight = async () => {
  const response = await fetch('https://bartio.rpc.berachain.com', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      jsonrpc: '2.0',
      method: 'eth_blockNumber',
      params: [],
      id: 84
    })
  });

  const data = await response.json();
  // Convert hex to number
  const blockNumber = parseInt(data.result, 16);
  return blockNumber;
}

class CryptoManager {
  static CONTRACT_ADDRESS = "0x9e78743fFA72a5177A74Bad9CbC9355db5f41B00";
  static CONTRACT_ABI = [
    {
      "inputs": [],
      "name": "claim",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "id",
          "type": "uint256"
        }
      ],
      "name": "claimNFT",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "energyBlock",
          "type": "uint256"
        }
      ],
      "name": "fight",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "initFight",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "referer",
          "type": "address"
        }
      ],
      "name": "registerAcc",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_replenishTime",
          "type": "uint256"
        }
      ],
      "name": "setEnergyReplenishTime",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_maxEnergy",
          "type": "uint256"
        }
      ],
      "name": "setMaxEnergy",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "swapVal",
          "type": "uint256"
        }
      ],
      "name": "swap",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "energyReplenishTime",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_userAddress",
          "type": "address"
        }
      ],
      "name": "getUserEnergyCount",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "isOwner",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "maxEnergy",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "referral",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "rewards",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "userActions",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "swap",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "fights",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "delegates",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "claims",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "userDelegates",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "userEnergy",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ];

  static STAKING_CONTRACT_ADDRESS = "0xA63279BD1b46b7Ff5b60C22E837F24002420ED89";
  static STAKING_CONTRACT_ABI = [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_rewardsToken",
          "type": "address"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "address",
          "name": "oldOwner",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "OwnerChanged",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "address",
          "name": "newOwner",
          "type": "address"
        }
      ],
      "name": "OwnerNominated",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "bool",
          "name": "isPaused",
          "type": "bool"
        }
      ],
      "name": "PauseChanged",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "address",
          "name": "token",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "Recovered",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "reward",
          "type": "uint256"
        }
      ],
      "name": "RewardAdded",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "reward",
          "type": "uint256"
        }
      ],
      "name": "RewardPaid",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "newDuration",
          "type": "uint256"
        }
      ],
      "name": "RewardsDurationUpdated",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "Staked",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "Withdrawn",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "acceptOwnership",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "earned",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "exit",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getReward",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getRewardForDuration",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "lastPauseTime",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "lastTimeRewardApplicable",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "lastUpdateTime",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "a",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "b",
          "type": "uint256"
        }
      ],
      "name": "min",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "pure",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_owner",
          "type": "address"
        }
      ],
      "name": "nominateNewOwner",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "nominatedOwner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "reward",
          "type": "uint256"
        }
      ],
      "name": "notifyRewardAmount",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "owner",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "paused",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "periodFinish",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "tokenAddress",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "tokenAmount",
          "type": "uint256"
        }
      ],
      "name": "recoverERC20",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "rewardPerToken",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "rewardPerTokenStored",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "rewardRate",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "rewards",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "rewardsDuration",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "rewardsToken",
      "outputs": [
        {
          "internalType": "contract IERC20",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bool",
          "name": "_paused",
          "type": "bool"
        }
      ],
      "name": "setPaused",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_rewardsDuration",
          "type": "uint256"
        }
      ],
      "name": "setRewardsDuration",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "stake",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "userRewardPerTokenPaid",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "withdraw",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ];

  constructor() {
    this.provider = null;
    this.signer = null;
    this.contract = null;
    this.stakingContract = null;
    this.isInitialized = false;
  }

  checkHasWallet() {
    if (typeof window.ethereum === "undefined") {
      throw new NoWalletError();
    }
  }

  async checkReadyForTransaction() {
    if (!this.isInitialized) await this.init();
    this.checkHasWallet();
    // await this.checkChainId();
  }

  async checkChainId() {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum)
      let chainId = Number((await provider.getNetwork()).chainId)
      if (chainId !== 80084) {
        throw new IncorrectChainError();
      }
    } catch{
      throw new IncorrectChainError();
    }
  }

  async addBerachain() {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x138d4" }],
      });
    } catch(e) {
      console.log('add wallet: ', e);
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x138d4",
            rpcUrls: ["https://bartio.rpc.berachain.com/"],
            chainName: "Berachain bArtio",
            nativeCurrency: {
              name: "BERA",
              symbol: "BERA",
              decimals: 18,
            },
            blockExplorerUrls: ["https://bartio.beratrail.io/"],
          },
        ]
      });    
  }
  }

  async init() {
    this.checkHasWallet();

    try {
      await this.checkChainId();
    } catch {
      await this.addBerachain();
    }
    
    this.provider = new ethers.BrowserProvider(window.ethereum);
    this.signer = await this.provider.getSigner();
    
    this.contract = new ethers.Contract(
      CryptoManager.CONTRACT_ADDRESS,
      CryptoManager.CONTRACT_ABI,
      this.signer
    );

    this.stakingContract = new ethers.Contract(
      CryptoManager.STAKING_CONTRACT_ADDRESS,
      CryptoManager.STAKING_CONTRACT_ABI,
      this.signer
    );
    this.isInitialized = true;
  }

  async getConnectedAddresses() {
    if (typeof window.ethereum === "undefined") {
      return [];
    }
    const accounts = await window.ethereum.request({method: 'eth_accounts'});
    console.log(`accounts: ${accounts}`)
    return accounts;
  }

  async getActiveAddress() {
    return await this.#handleEthers(() => this.signer.getAddress());
  }

  async registerAccount(refererAddress) {
    return await this.#handleEthers(() => this.contract.registerAcc(refererAddress));
  }

  async isAddress(address) {
    return await this.#handleEthers(() => ethers.isAddress(address));
  }

  async isRegistered(address) {
    const res = await this.#handleEthers(() => this.contract.referral(address));
    return res !== "0x0000000000000000000000000000000000000000";
  }

  async swap(swapVal) {
    return await this.#handleEthers(() => this.contract.swap(swapVal));
  }

  async fight() {
    const activeAddress = await this.getActiveAddress()
    const numOfEnergyInitialized = await this.getUserEnergyCount(activeAddress);
    const maxEnergy = await this.contract.maxEnergy();
    const energyReplenishTime = await cryptoManager.getEnergyReplenishTime();  
    const currentTime = Math.floor(Date.now() / 1000);

    if (numOfEnergyInitialized < maxEnergy){
      return await this.#handleEthers(() => this.contract.initFight());
    } else {
      for (let i = 0; i < maxEnergy; i++) {
        const energyBlock = await cryptoManager.getEnergyBlock(activeAddress, i);
        //Add 20 seconds to make account for block delay
        if (currentTime - energyBlock >= energyReplenishTime + 20) {
          return this.contract.fight(i);
        }
      }
    }
  }

  async stake(amount) {
    return await this.#handleEthers(() => this.stakingContract.stake({
      value: ethers.parseEther(amount.toString()),
    }));
  }

  async withdraw(amount) {
    return await this.#handleEthers(() => this.stakingContract.withdraw(ethers.parseEther(amount.toString())));
  }

  async getStakedAmount(address) {
    const amount = await this.#handleEthers(() => this.stakingContract.balanceOf(address));
    return ethers.formatUnits(amount, 18); // Assuming 18 decimals for the token
  }

  async claimNFT(id) {
    return await this.#handleEthers(() => this.contract.claimNFT(id));
  }

  async getUserEnergyCount(address) {
    return Number(await this.#handleEthers(() => this.contract.getUserEnergyCount(address)));
  }

  async getEnergyReplenishTime() {
    return Number(await this.#handleEthers(() => this.contract.energyReplenishTime()));
  }

  async getMaxEnergy() {
    return Number(await this.#handleEthers(() => this.contract.maxEnergy()));
  }

  async getEnergyBlock(address, i) {
    return Number(await this.#handleEthers(() => this.contract.userEnergy(address, i)));
  }

  async getRewards() {
    return await this.#handleEthers(() => this.contract.getRewards());
  }

  async getUserActions(address) {
    return await this.#handleEthers(() => this.contract.userActions(address));
  }

  async getUserDelegates(address) {
    return await this.#handleEthers(() => this.stakingContract.balanceOf(address));
  }

  async getAvailableEnergy(address) {
    const numofInitializedEnergyCount = await cryptoManager.getUserEnergyCount(address);
    const energyReplenishTime = await cryptoManager.getEnergyReplenishTime();  
    const maxEnergy = await cryptoManager.getMaxEnergy();  

    let availableEnergy = maxEnergy - numofInitializedEnergyCount;

    const currentTime = Math.floor(Date.now() / 1000);


  
    for (let i = 0; i < numofInitializedEnergyCount; i++) {
      const energyBlock = await cryptoManager.getEnergyBlock(address, i);
      if (currentTime - energyBlock >= energyReplenishTime) {
        availableEnergy += 1;
      }
    }
  
    return availableEnergy;
  }

  setupAccountChangeListener(onChanged) {
    window.ethereum.on('accountsChanged', async (accounts) => {
      console.log("Changed account")  
      if (accounts.length === 0) {
            onChanged();
        } else {
            if (this.isInitialized) {
              this.signer = this.provider.getSigner();
            }
            onChanged();
        }
    });
  }

  /**
   * Do pre-tx checks and handle ethers.js errors from wallet REQUEST based on https://docs.ethers.org/v6/api/utils/errors/.
   * @param {Function} func - Any functions that involve ethers.js operations.
   */
  async #handleEthers(func){
    await this.checkReadyForTransaction();
    
    try {
      return await func();
    } catch (error) {
      // User rejected request
      if (error.code === "ACTION_REJECTED") {
        throw new UserRejectedRequestError();
      } else if (error.code === "UNSUPPORTED_OPERATION") {
        throw new UnsupportedOperationError();
      } else {
        throw error;
      }
    }
  }
}

/**
 * Wait for a contract call RESULT and handle:
 * @param {Function} contractCallFunction - Contract calls to be executed. e.g. () => cryptoManager.delegate(...)
 * @param {Function} onRequested - Callback for when the contract call has been requested SUCCESSFULLY.
 * @param {Function} onSuccess - Callback for when the contract call result is successful.
 * @param {Function} onCallException - Callback for when the contract call throws an exception.
 * @param {Function} onCallReplaced - Callback for when the contract call is replaced. Cancelling tx on MetaMask is cassified as replacement.
 */
export const waitContractCall = async (contractCallFunction,
        { confirmations = 1, 
          onRequested = () => {}, onSuccess = () => {}, onCallException = () => {}, onCallReplaced = () => {}
        } = {}) => {
  const tx = await contractCallFunction();
  onRequested();
  return tx.wait(confirmations)
          .then((result) => {
            onSuccess(result);
          })
          .catch((error => {
            console.log("error: ", error);
            if (error.code === "CALL_EXCEPTION") {
              onCallException(error);
            } else if (error.code === "TRANSACTION_REPLACED") {
              onCallReplaced(error);
            } else {
              onCallException(error);
            }
          }))
        }

export const cryptoManager = new CryptoManager();
