import { cryptoManager, NoWalletError, UnsupportedOperationError, UserRejectedRequestError } from "./Crypto.js";
import { KeyboardMenu } from "./KeyboardMenu.js";
import { Hud } from "./Hud.js";
import { showToast } from "./Components/Toast.js";

export class TitleScreen {

  showLoadingProgressBar() {
    this.loadingOverlay = document.createElement("div");
    this.loadingOverlay.classList.add("LoadingOverlay");
    this.loadingOverlay.innerHTML = `
      <div class="LoadingContent">
        <div class="LoadingText">Loading your Berachain Adventure</div>
        <div class="ProgressBarContainer">
          <div class="ProgressBar"></div>
        </div>
      </div>
    `;
    document.body.appendChild(this.loadingOverlay);

    this.progressBar = this.loadingOverlay.querySelector(".ProgressBar");
    this.updateProgressBar();
  }

  updateProgressBar() {
    const updateInterval = 50; // milliseconds
    const progressIncrement = 5;
    let currentProgress = 0;

    const update = () => {
      currentProgress += progressIncrement;
      this.progressBar.style.width = `${currentProgress}%`;

      if (currentProgress < 100) {
        setTimeout(update, updateInterval);
      } else {
        this.hideLoadingProgressBar();
      }
    };

    setTimeout(update, updateInterval);
  }

  hideLoadingProgressBar() {
    if (this.loadingOverlay) {
      this.loadingOverlay.remove();
    }
  }

  getButtonOptions() {
    return [
      {
        label: "Start Game",
        description: "Enter a referral wallet address and get a 10% bonus.",
        handler: async () => {
          const referralAddressInput = document.querySelector("#referralCodeInput");
          const referralAddress = referralAddressInput ? referralAddressInput.value : "";
          try {
            const connectedAddress = await cryptoManager.getConnectedAddresses();

            if (typeof window.ethereum !== "undefined" && connectedAddress.length === 0) {
              console.log('wallet_requestPermissions');
              try {
                await window.ethereum.request({
                  method: 'wallet_requestPermissions',
                  params: [{ eth_accounts: {} }],
                });
              } catch(e){
                console.log('error: ', e);
              }
            }

            const address = await cryptoManager.getActiveAddress();
            const isRegistered = await cryptoManager.isRegistered(address);
            const energyLevel = await cryptoManager.getAvailableEnergy(address);
            const isValidAddress = await cryptoManager.isAddress(referralAddress);

            console.log('Energy Level: ', energyLevel);

            if (!isRegistered) {
              if (isValidAddress){
                await cryptoManager.registerAccount(referralAddress);
              } else {
                await cryptoManager.registerAccount(address);
              }
            }

            this.close();
            
            const { Overworld } = await import("./Overworld.js");
            const overworld = new Overworld({
              element: document.querySelector(".game-container")
            });
            overworld.initGame();
          } catch (error) {
            console.log('error: ', error);
            if (error instanceof NoWalletError) {
              showToast("You need Metamask to play! Please install it to play!");
            } else if (error instanceof UserRejectedRequestError) {
              showToast("You must register in order to play!");
            } else if (error instanceof UnsupportedOperationError) {
              showToast("Your wallet is not connected!");
            } else {
              console.error(error);
              showToast("You need to be connected to BeraChain bArtio to play!");
            }
          }
        },
      },
    ];
  }

  async createElement(address) {
    this.element = document.createElement("div");
    this.element.classList.add("TitleScreen");
    this.element.innerHTML = `
      <img class="TitleScreen_logo" src="/images/logo.png" alt="BERA Kingdoms" />
      <input type="text" id="referralCodeInput" placeholder="Enter referrer's wallet address (Optional)" class="referralCodeInput" />
    `;
    this.logoElement = this.element.querySelector(".TitleScreen_logo");
    this.logoElement.style.visibility = "hidden"; // Hide the logo initially

    const referralCodeInput = this.element.querySelector("#referralCodeInput");
    referralCodeInput.addEventListener("input", () => {
      const startGameButton = this.element.querySelector(".KeyboardMenu_option button");
      if (startGameButton) {
        startGameButton.disabled = referralCodeInput.value.trim() === "";
      }
    });

    const isRegistered = await cryptoManager.isRegistered(address);


    if (address && isRegistered) {
      referralCodeInput.style.display = "none";
    }
  }

  close() {
    this.keyboardMenu.end();
    this.element.remove();
  }
  

  showLogoWithPopInEffect() {
    this.logoElement.style.visibility = "visible";
    this.logoElement.classList.add("PopInAnimation");
  }

  async init(container) {
    const connectedAddresses = await cryptoManager.getConnectedAddresses();
    const address = connectedAddresses.length > 0 ? connectedAddresses[0] : null;

    this.showLoadingProgressBar();
    this.createElement(address);
    container.appendChild(this.element);

    const hud = new Hud(address, address, true);
    hud.init(this.element);

    this.keyboardMenu = new KeyboardMenu();
    this.keyboardMenu.init(this.element);
    this.element.classList.add("fadeIn");
    
    this.keyboardMenu.setOptions(this.getButtonOptions());

    // Show the logo with the pop-in effect after a short delay
    setTimeout(() => {
      this.showLogoWithPopInEffect();
    }, 1000); // Adjust the delay as needed
  }

  
}

document.head.innerHTML += `
  <style>
      .PopInAnimation {
        animation-name: fadeIn;
        animation-duration: 0.5s;
        animation-timing-function: ease-in;
      }
      
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    .LoadingOverlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000; /* Set the background color to your desired color */
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
    }

    .LoadingContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
      position: inherit;
      @media (max-width: 1450px) {
        top: 400px;
      }
      @media (max-width: 1100px) {
        top: 250px;
      }
      @media (max-width: 720px) {
        top: 50px;
      }
      top: 450px;
    }

    .LoadingText {
      color: #fff;
      @media (max-width: 1450px) {
        font-size: 22px;
      }
      @media (max-width: 1100px) {
        font-size: 18px;
      }
      @media (max-width: 720px) {
        font-size: 10px;
      }
        font-size: 25px;
      margin-bottom: 10px;
    }

    .ProgressBarContainer {
      width: 100%;
      height: 10px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      overflow: hidden;
    }

    .ProgressBar {
      height: 100%;
      background: #FBD623; /* Set the progress bar color to your desired color */
      transition: width 0.1s ease-in-out;
    }

    .referralCodeInput {
      display: block;
      margin: 5px auto;
      font-size: 8px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 210px;
      height: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .referralCodeInput::placeholder {
      color: #aaa;
    }
  </style>
`;
