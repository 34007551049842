class Toast {
  constructor(text, duration, type) {
    this.text = text;
    this.duration = duration;
    this.type = type; // "info", "success", "error"
    this.element = null;
  }

  createElement() {
    this.element = document.createElement("div");
    this.element.classList.add("Toast", `Toast_${this.type}`);
    this.element.innerHTML = `
      <p class="Toast_text">${this.text}</p>
    `;
  }

  init(container) {
    this.createElement();
    container.appendChild(this.element);
    
    // Trigger entrance animation
    requestAnimationFrame(() => {
      this.element.classList.add("Toast--visible");
    });

    // Remove after duration
    setTimeout(() => {
      this.element.classList.remove("Toast--visible");
      this.element.addEventListener("transitionend", () => {
        this.element.remove();
      });
    }, this.duration);
  }
}

export const showToast = (text, {duration = 2500, type = "info"} = {}) => {
  const toast = new Toast(text, duration, type);
  toast.init(document.querySelector(".game-container"));
}