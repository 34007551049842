export const MonsterTypes = {
  normal: "normal",
  spicy: "spicy",
  veggie: "veggie",
  fungi: "fungi",
  chill: "chill",
};

export const Monsters = {
  s001: {
    name: "Bera Bunny",
    description: "The OG BERA Monster",
    type: MonsterTypes.spicy,
    src: "/images/characters/monsters/s001.png",
    icon: "/images/icons/spicy.png",
    actions: ["burningStatus", "damage1", "saucyStatus"],
  },
  s002: {
    name: "Bera Brigade",
    description: "A salty warrior who fears nothing",
    type: MonsterTypes.spicy,
    src: "/images/characters/monsters/s002.png",
    icon: "/images/icons/spicy.png",
    actions: ["damage1", "saucyStatus", "clumsyStatus"],
  },
  s003: {
    name: "Bera Bud",
    description: "A yellow monkey",
    type: MonsterTypes.spicy,
    src: "/images/characters/monsters/s003.png",
    icon: "/images/icons/spicy.png",
    actions: ["damage2", "saucyStatus"],
  },
  s004: {
    name: "Bera Bum",
    description: "A yellow monkey",
    type: MonsterTypes.chill,
    src: "/images/characters/monsters/s004.png",
    icon: "/images/icons/spicy.png",
    actions: ["damage1", "saucyStatus"],
  },
  s005: {
    name: "Bera Doc",
    description: "A artsey monkey",
    type: MonsterTypes.chill,
    src: "/images/characters/monsters/s005.png",
    icon: "/images/icons/spicy.png",
    actions: ["damage1", "saucyStatus"],
  },
  s006: {
    name: "Bera Agent",
    description: "A BERA monster",
    type: MonsterTypes.veggie,
    src: "/images/characters/monsters/s006.png",
    icon: "/images/icons/veggie.png",
    actions: ["damage1"],
  },
  s007: {
    name: "Bera Kiddo",
    description: "A BERA monster",
    type: MonsterTypes.veggie,
    src: "/images/characters/monsters/s007.png",
    icon: "/images/icons/veggie.png",
    actions: ["damage1"],
  },
  s008: {
    name: "Bera Zombie",
    description: "A BERA monster",
    type: MonsterTypes.veggie,
    src: "/images/characters/monsters/s008.png",
    icon: "/images/icons/veggie.png",
    actions: ["damage1"],
  },
  s009: {
    name: "Bera Fighter",
    description: "A BERA monster",
    type: MonsterTypes.veggie,
    src: "/images/characters/monsters/s009.png",
    icon: "/images/icons/veggie.png",
    actions: ["damage1"],
  },
  s010: {
    name: "Bera Budds",
    description: "A BERA monster",
    type: MonsterTypes.veggie,
    src: "/images/characters/monsters/s010.png",
    icon: "/images/icons/veggie.png",
    actions: ["damage1"],
  },
  s011: {
    name: "Bera Punk",
    description: "A BERA monster",
    type: MonsterTypes.veggie,
    src: "/images/characters/monsters/s011.png",
    icon: "/images/icons/veggie.png",
    actions: ["damage1"],
  },
  s012: {
    name: "Bera Mobster",
    description: "A BERA monster",
    type: MonsterTypes.fungi,
    src: "/images/characters/monsters/s012.png",
    icon: "/images/icons/fungi.png",
    actions: ["damage1"],
  },
  s013: {
    name: "Bera Jason",
    description: "A BERA monster",
    type: MonsterTypes.fungi,
    src: "/images/characters/monsters/s013.png",
    icon: "/images/icons/fungi.png",
    actions: ["damage1"],
  },
  s014: {
    name: "Bera Nerd",
    description: "A BERA monster",
    type: MonsterTypes.fungi,
    src: "/images/characters/monsters/s014.png",
    icon: "/images/icons/fungi.png",
    actions: ["damage1"],
  },
};
