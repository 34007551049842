import { cryptoManager } from "./Crypto.js";
import { Combatant } from "./Battle/Combatant.js";
import { Monsters } from "./Content/monsters.js";
import { playerState } from "./State/PlayerState.js";
import { HudAddress } from "./HudAddress.js";

export class Hud {
  constructor(address, ethAddress, addressOnly = false) {
    this.scoreboards = [];
    this.walletAddress = address;
    this.ethAddress = ethAddress;
    this.addressOnly = addressOnly;
  }

  hide() {
    this.element.style.display = 'none';
  }

  update() {
    this.scoreboards.forEach((s) => {
      s.update(playerState.monsters[s.id]);
    });
  }

  close() {
    this.element.remove();
  }

  async createElement() {
    if (this.element) {
      this.element.remove();
      this.scoreboards = [];
    }

    this.element = document.createElement("div");
    this.element.classList.add("Hud");

    const hudAddress = new HudAddress(this.walletAddress);
    hudAddress.init(this.element);


    if (this.addressOnly) { 
      return;
    }

    this.mapElement = document.createElement("div");
    this.mapElement.classList.add("MapElement");

    let currentMap = window.localStorage.getItem("currentMap");

    const userActions = await cryptoManager.getUserActions(this.walletAddress);

    const userDelegates = await cryptoManager.getUserDelegates(this.walletAddress);

    let userPoints = Number(userActions.fights) * 2 + Math.floor(Number(userDelegates) / 1e16) + Number(userActions.claims) * 2 + Number(userActions.swap) * 2
    console.log("CURRENT POINTS: ", userPoints);
    currentMap = "BERACHAIN";


    this.mapElement.innerHTML = `
      <p class="MapElement_name">SCORE: ${userPoints}</p>
    `;

    if (userPoints > 9){
      this.mapElement.innerHTML = `
      <p class="MapElement_name_10">SCORE: ${userPoints}</p>
    `;
    }
    
    if (userPoints > 99){
      this.mapElement.innerHTML = `
      <p class="MapElement_name_100">SCORE: ${userPoints}</p>
    `;
    }
    
    if (userPoints > 999){
      this.mapElement.innerHTML = `
      <p class="MapElement_name_1000">SCORE: ${userPoints}</p>
    `;
    }
    

    this.element.appendChild(this.mapElement);

    playerState.lineup.forEach((key) => {
      const monster = playerState.monsters[key];
      const scoreboard = new Combatant(
        {
          id: key,
          ...Monsters[monster.monsterId],
          ...monster,
        },
        null
      );
      scoreboard.createElement();
      this.scoreboards.push(scoreboard);
      this.element.appendChild(scoreboard.hudElement);
    });
    this.update();
  }

  init(container) {
    this.createElement();
    container.appendChild(this.element);

    document.addEventListener("PlayerStateUpdated", () => {
      this.update();
    });

    document.addEventListener("LineupChanged", () => {
      this.createElement();
      container.appendChild(this.element);
    });

    document.addEventListener("MapChanged", () => {
      this.createElement();
      container.appendChild(this.element);
    });
  }
}
