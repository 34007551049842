import { utils } from "../utils.js";

const MAX_LINEUP_MONSTERS = 3;

class PlayerState {
  constructor() {
    this.monsters = {
      "p1": {
        monsterId: "s001",
        hp: 50,
        maxHp: 50,
        xp: 0,
        maxXp: 100,
        level: 1,
        status: null,
      },
      // "p2": {
      //   monsterId: "s009",
      //   hp: 50,
      //   maxHp: 50,
      //   xp: 75,
      //   maxXp: 100,
      //   level: 1,
      //   status: null,
      // },
      // "p3": {
      //   monsterId: "s012",
      //   hp: 50,
      //   maxHp: 50,
      //   xp: 75,
      //   maxXp: 100,
      //   level: 1,
      //   status: null,
      // }
    }
    this.lineup = ["p1"];
    this.items = [
      { actionId: "item_recoverHp", instanceId: "item1" },
      { actionId: "item_recoverHp", instanceId: "item2" },
      { actionId: "item_recoverHp", instanceId: "item3" },
    ]
    this.storyFlags = {
    };
  }

  addMonster(monsterId) {
    const newId = `p${Date.now()}`+Math.floor(Math.random() * 99999);
    this.monsters[newId] = {
      monsterId,
      hp: 50,
      maxHp: 50,
      xp: 0,
      maxXp: 100,
      level: 1,
      status: null,
    }
    if (this.lineup.length < 3) {
      this.lineup.push(newId)
    }
  }

  swapLineup(oldId, incomingId) {
    const oldIndex = this.lineup.indexOf(oldId);
    this.lineup[oldIndex] = incomingId;
    utils.emitEvent("LineupChanged");
  }

  moveToFront(futureFrontId) {
    this.lineup = this.lineup.filter(id => id !== futureFrontId);
    this.lineup.unshift(futureFrontId);
    if (this.lineup.length > MAX_LINEUP_MONSTERS) {
      this.lineup.pop();
    }
    utils.emitEvent("LineupChanged");
  }

  healAllMonsters() {
    Object.keys(this.monsters).forEach(id => {
      const monster = this.monsters[id];
      monster.hp = monster.maxHp;
      monster.status = null;
    });
    utils.emitEvent("LineupChanged");
  }

}

export const playerState = new PlayerState();