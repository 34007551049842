import './styles/main.css';
import { getBlockHeight, cryptoManager } from "./Crypto.js";
import { TitleScreen } from "./TitleScreen.js";

getBlockHeight()
  .then((blockNumber) => {
    const glowCircleElement =
      document.querySelector(".block-height-text");
    glowCircleElement.textContent = blockNumber;
  })
  .catch((error) => {
    console.error("Error fetching block height:", error);
  });

window.onload = function () {
  document
    .querySelector(".game-canvas")
    .addEventListener("click", function () {
      var audio = document.getElementById("myAudio");
      audio.volume = 0.1; // 50% volume
      audio.paused ? audio.play() : audio.pause();
    });
};

const container = document.querySelector(".game-container");
const titleScreen = new TitleScreen();

await titleScreen.init(container);
cryptoManager.setupAccountChangeListener(() => window.location.reload());